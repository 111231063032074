@font-face {
  font-family: "Roboto-Thin";
  src:
    local("Roboto-Thin"),
    url("/assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src:
    local("Roboto-Regular"),
    url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto-Medium"),
    url("/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto-Bold"),
    url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}
