@use "sass:map";
@use "fonts";
@use "vars";

body,
html {
  height: 100%;
}
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
  background-color: vars.$color-white;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button.rounded-border-button {
  border-radius: 30px;
}

button.mat-mdc-mini-fab icon-download {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.white-background {
  // backgound of the field
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white;
  }
  // text of the field
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: vars.$color-blue;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input,
  .mat-mdc-select {
    color: vars.$color-blue-dark;
  }
  // underline of the field
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before,
  .mdc-text-field--filled:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-line-ripple::before {
    border-bottom-color: vars.$color-blue;
  }

  // datepicker
  .mat-datepicker-toggle {
    color: vars.$color-blue;
    font-size: 16px !important;
  }

  .mat-date-range-input {
    color: vars.$color-blue-dark;
  }
  .mat-mdc-header-cell .mat-mdc-form-field .mat-mdc-form-field-infix {
    color: vars.$color-blue;
  }
}

.blue-dark-background {
  // backgound of the field
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: vars.$color-blue-dark;
  }
  // text of the field
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: vars.$color-white;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input,
  .mat-mdc-select {
    color: vars.$color-white;
  }
  // underline of the field
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before,
  .mdc-text-field--filled:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-line-ripple::before {
    border-bottom-color: vars.$color-white;
  }

  .mat-date-range-input {
    color: vars.$color-white;
  }
  .mat-mdc-header-cell .mat-mdc-form-field .mat-mdc-form-field-infix {
    color: vars.$color-white;
  }

  // icon
  .mat-unthemed .mat-icon {
    color: vars.$color-white;
  }

  // datepicker
  .mat-datepicker-toggle,
  .mat-date-range-input,
  .mat-date-range-input-separator,
  .mat-mdc-header-cell .mat-mdc-form-field .mat-mdc-form-field-infix {
    color: vars.$color-white;
  }

  // select
  .mat-mdc-select-arrow {
    color: vars.$color-white;
  }
}

// table header
.mat-mdc-header-cell {
  font-size: 16px;
  font-weight: 600;
  .mat-mdc-form-field {
    width: 100%;
    .mat-mdc-form-field-infix {
      display: flex;
      font-weight: bold;
      letter-spacing: normal;
    }
  }
}

// datepicker calendar button
.mat-calendar-period-button {
  color: vars.$color-blue-dark !important;
}

// button icon and text
.mat-unthemed {
  .mat-icon,
  .mdc-button__label {
    color: vars.$color-blue;
  }
}

// button primay & fab & mini fab icon
.mat-mdc-icon-button:not([disabled]).mat-primary,
.mat-mdc-unelevated-button:not([disabled]).mat-primary,
.mat-mdc-raised-button:not([disabled]).mat-primary,
.mat-mdc-fab:not([disabled]).mat-primary,
.mat-mdc-mini-fab:not([disabled]).mat-primary {
  .mat-icon,
  .mdc-button__label {
    color: vars.$color-white;
  }
}

// chip primary
.mat-primary.mat-mdc-chip-option.mat-mdc-standard-chip:not(
    .mdc-evolution-chip--disabled
  ) {
  background-color: vars.$color-blue;
  .mdc-evolution-chip__text-label {
    color: vars.$color-white;
  }
}

// checkbox primary & list option
.mat-mdc-checkbox.mat-primary
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark,
.mat-mdc-checkbox.mat-primary
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__mixedmark,
.mat-mdc-list-option
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: vars.$color-white !important;
  border-color: vars.$color-white;
}

// fix select width
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

// drawers
.mat-drawer-container {
  background-color: unset !important;
}

// dialog title
.mat-mdc-dialog-container h1.mdc-dialog__title {
  font-weight: 600;
  color: vars.$color-blue-dark;
}

// used by all side panels
.side-panel-body .header-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  padding: 0 16px;
  height: 48px;
  width: 100%;
  color: vars.$color-white;
  background-color: vars.$color-blue-dark;
  border-radius: 10px 10px 0 0;

  .mat-mdc-checkbox label {
    color: vars.$color-white;
  }
}
