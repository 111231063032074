$header-height: 70px;

$color-blue-dark: #3d6984;
$color-blue-grey: #4c718f;
$color-blue-icon: #4a7190;
$color-blue: #009de0;
$color-blue-secondary: #00ace9;
$color-blue-light: #7ac6ff;
$color-blue-lighter: #e2f4fc;
$color-blue-lightest: #f0fbff;
$color-white: #fff;
$color-red: #b35a32;
$color-pink: #ff7a7a;
$color-blue-cerulean: #00ace9;
$color-orange: #ff9800;
$color-orange-light: #ffca83;
$color-red-orange: #e64a19;
$color-warn: #e7a04d;

$color-shadow: rgba(173, 173, 173, 0.2);

$md-color-blue: (
  50: #e5f3ff,
  100: #bee0fe,
  200: $color-blue-lightest,
  300: $color-blue-lighter,
  400: $color-blue-light,
  500: $color-blue,
  600: #2291fa,
  700: #1c86fa,
  800: #177cf9,
  900: $color-blue-dark,
  A100: #ffffff,
  A200: #f0f5ff,
  A400: #bdd4ff,
  A700: #a3c4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-blue-cerulean: (
  50: #e0f5fc,
  100: #b3e6f8,
  200: #80d6f4,
  300: #4dc5f0,
  400: #26b8ec,
  500: $color-blue-cerulean,
  600: #00a5e6,
  700: #009be3,
  800: #0092df,
  900: #0082d9,
  A100: #ffffff,
  A200: #cee9ff,
  A400: #9bd2ff,
  A700: #81c6ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-red: (
  50: #f6ebe6,
  100: #e8cec2,
  200: #d9ad99,
  300: #ca8c70,
  400: #be7351,
  500: $color-red,
  600: #ac522d,
  700: #a34826,
  800: #9a3f1f,
  900: #8b2e13,
  A100: #ffcbbe,
  A200: #ffa18b,
  A400: #ff7858,
  A700: #ff633f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-blue-dark: (
  50: "#dff0ff",
  100: "#bdd8ea",
  200: "#9ebcd2",
  300: "#7da0ba",
  400: "#648ba7",
  500: "#4a7795",
  600: $color-blue-dark,
  700: "#2e556e",
  800: "#204258",
  900: "#0e2e40",
  A100: #ffffff,
  A200: #f0f5ff,
  A400: #bdd4ff,
  A700: #a3c4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
